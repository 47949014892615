import * as qiniu from 'qiniu-js'
import {getQiNiuToken} from '@/api/UploadApi'
import {msg_err} from '@/utils/ele_component'
import {date_format} from "@/utils/common";

class BaseUploadModel {
    // 数组转为upload数组
    static arrayToUploadArray(arr) {
        const arr_return = []
        arr.forEach(function (item) {
            arr_return.push({
                url: item
            })
        })
        return arr_return
    }

    // upload数组转为普通数组
    static uploadArrayToArray(arr) {
        const arr_return = []
        arr.forEach(function (item) {
            arr_return.push(item.url)
        })
        return arr_return
    }

    // 七牛云不同文件存不同bucket对应的域名
    static getBucketDomain(file) {
        const domain = {
            'image': 'http://image.cdzyhd.com',
            'video': 'http://audio.cdzyhd.com',
            'audio': 'http://audio.cdzyhd.com',
            'other': 'http://resouce.cdzyhd.com'
        }
        const fileType = this.getFileType(file)[0]
        let returnDomain = ''
        switch (fileType) {
            case 'image':
                returnDomain = domain['image']
                break
            case 'video':
                returnDomain = domain['video']
                break
            case 'audio':
                returnDomain = domain['audio']
                break
            default:
                returnDomain = domain['other']
                break
        }
        // 固定为http
        returnDomain = 'https://resouce.cdzyhd.com'
        return returnDomain
    }

    // 获取七牛云上传token
    static async getQiniuUploadToken(fileType) {
        // 文件类型不同上传到不同的bucket
        let bucket = ''
        switch (fileType) {
            case 'image':
                bucket = 'czy-image'
                break
            case 'video':
                bucket = 'czy-audio'
                break
            case 'audio':
                bucket = 'czy-audio'
                break
            default:
                bucket = 'zyhd-resouce'
                break
        }
        // 固定为resouce
        bucket = 'zyhd-resouce'
        const [data] = await getQiNiuToken(bucket)
        return data.data
    }

    // 获取随机文件名
    static getUuid() {
        const s = []
        const hexDigits = '0123456789abcdef'
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
        }
        s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-'

        const uuid = s.join('')
        return uuid
    }

    // 七牛云上传
    static async qiNiuUpload(file, f) {
        // 获取上传token
        const token = await this.getQiniuUploadToken(this.getFileType(file)[0])
        const nameArr = file['name'].split('.')
        const type = nameArr[nameArr.length - 1]
        let key = this.getUuid() + '.' + type // 上传后文件资源名以设置的 key 为主，如果 key 为 null 或者 undefined，则文件资源名会以 hash 值作为资源名。

        let schoolId = JSON.parse(sessionStorage.getItem("expUserInfo"))["schoolId"]
        let date = date_format(new Date(), "yyyy-MM-dd")
        key = "/expUpload/" + schoolId + "/" + date + "/" + key

        const config = {
            concurrentRequestLimit: 1,
            useCdnDomain: false, // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
            region: qiniu.region.z2 // 根据具体提示修改上传地区,当为 null 或 undefined 时，自动分析上传域名区域
        }

        const putExtra = {
            fname: file.name, // 文件原文件名
            params: {}, // 用来放置自定义变量
            mimeType: null // 用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
        }
        const observable = qiniu.upload(file, key, token, putExtra, config)
        observable.subscribe(f)
    }

    // element upload的上传方法
    static async uploadRequest(upload, target) {
        let file = upload.file
        return await new Promise((resolve, reject) => {
            if (!target || target === "qiniu") {
                BaseUploadModel.qiNiuUpload(file, {
                    next: (result) => {
                    },
                    error: (errResult) => {
                        console.log(errResult)
                        msg_err('上传失败')
                    },
                    complete: (result) => {
                        let domain = BaseUploadModel.getBucketDomain(file)
                        let url = domain + '/' + result.key + ''
                        resolve({data: url})
                    }
                })
            }
        })
    }

    // 限制文件类型
    static fileTypeLimit(file, type) {
        let [fileType] = this.getFileType(file)
        return fileType === type;
    }

    // 各个场景的文件大小限制 单位KB
    static fileSizeLimit = {
        // 问题题干图片
        questionContentImg: 2048,
        // 问题题干视频
        questionContentVideo: 10240,
        // 问题题干 音频
        questionContentAudio: 10240,
        // 问题题干其他问题件
        questionContentOther: 10240
    }

    static fileTypeEnum = {
        'image': ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'],
        'video': ['video/mp4', 'video/avi'],
        'audio': ['audio/ogg', 'audio/mp3']
    }
    static fileSuffixEnum = {
        'image': ['jpg', 'png', 'jpeg', 'bmp', 'gif'],
        'video': ['mp4', 'ogg', 'avi', 'mov', 'rm', 'rmvb', '3gp', 'flv', 'mkv', 'm4v', 'mpg', 'mpeg', 'wmv', 'asf', 'asx'],
        'audio': ['ogg', 'mp3']
    }

    // 获取文件对应的文件类型
    static getFileType(file) {
        const fileType = file.type
        // console.log(fileType);
        const fileTypeMain = fileType.split('/')[0]
        const fileTypeSub = fileType.split('/')[1]
        return [fileTypeMain, fileTypeSub]
    }

    // 检测文件大小限制
    static checkFileSize(sizeLimit, file) {
        const size = file.size
        const sizeInput = parseInt(size / 1024)
        if (sizeLimit < sizeInput) {
            msg_err('文件必须小于' + sizeLimit + 'KB，当前文件大小是' + sizeInput + 'KB')
            return false
        } else {
            return true
        }
    }

    // 获取文件后缀名
    static getSuffix(v) {
        const nameArr = v.split('.')
        const fileSuffix = nameArr[nameArr.length - 1]
        return fileSuffix
    }
}

export {BaseUploadModel}
